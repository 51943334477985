import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
// import { catalogImportPlugin, CatalogImportPage } from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ExpandableNavigation, LightBox, ReportIssue, TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';
import {
  NotificationsPage,
} from '@backstage/plugin-notifications';
import { SignalsDisplay } from '@backstage/plugin-signals';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { ExplorePage } from '@backstage/plugin-explore';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { CarbonopsPage } from '@internal/backstage-plugin-carbonops';
import { VaultPage } from '@internal/backstage-plugin-vault';
import { WorklistPage, WorklistPageDetails } from '@internal/backstage-plugin-worklist';
import { HomePage } from '@internal/backstage-plugin-home';
import { ScaffolderPage, scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
// import { ScaffolderFieldExtensions } from '@internal/backstage-plugin-scaffolder-react';
import { CatalogImportPage, catalogImportPlugin } from '@internal/backstage-plugin-catalog-import';
import { CustomCatalogPage } from './components/catalog/CustomCatalogIndex';
import { CloudMonitorPage } from '@internal/backstage-plugin-cloud-monitor';
import { TimesaverPage } from '@internal/backstage-plugin-timesaver';
// import { RenderSupportButtonDrawer } from '@internal/backstage-plugin-support-button-react';
// import { TimeStampFieldExtension } from '@internal/backstage-plugin-hopper-list';
import { techDocsPage } from './components/techdocs/TechDocsPage';
import { CostInsightsPage } from '@internal/backstage-plugin-finops';
import { ScaffolderFieldExtensions } from '@internal/backstage-plugin-scaffolder-react';
import { TimeStampFieldExtension } from '@internal/backstage-plugin-timestamp-react';
import { SignedInUserExtension } from '@internal/backstage-plugin-signed-in-user-react';
import { ComponentHopperFieldExtension } from '@internal/backstage-plugin-component-hopper-react';
import { ConfluentCloudServiceAccountAccountLimiterFieldExtension } from '@internal/backstage-plugin-confluentcloud-serviceaccount-limiter-react';
import { AzureResourceGroupFieldValidateExtension } from '@internal/backstage-plugin-rg-name-validator-react'
import { AzureActiveDirectoryFieldExtension } from '@internal/backstage-plugin-azure-adgroups-list-react';
import { AzureADgroupFieldValidateExtension } from '@internal/backstage-plugin-adgroup-name-validator-react';
import { ConfluentCloudServiceAccountsListFieldExtension } from '@internal/backstage-plugin-confluentcloud-serviceaccount-create-react';
import { UpsertTopicConfigFieldExtension } from '@internal/backstage-plugin-confluentcloud-upserttopicconfig-react';
import { AzureSubscriptionFieldExtension, AzureNetworkFieldExtension } from '@internal/backstage-plugin-azure-react';
import { ValidateAzureAADGrpEmailFieldExtension } from '@internal/backstage-plugin-azuread-react';
import { BannerForUserRestrictionForCoPilot } from '@internal/backstage-plugin-copilot-banner-react';
import { CreateEcosystemBuilderCNForm } from '@internal/backstage-plugin-esb-cn-react';
import { CreateEcosystemBuilderV2Form } from '@internal/backstage-plugin-esb-ecc-react';
import { UpdateEcosystemBuilderExtension } from '@internal/backstage-plugin-esb-cn-update-react';
import { UpdateEcosystemBuilderExtensionV2 } from '@internal/backstage-plugin-esb-ecc-update-react';
import { GitHubRepositoryExtension } from '@internal/backstage-plugin-github-repos-react';
import { ApplicationFieldCustomExtension } from '@internal/backstage-plugin-application-id-react';
import { AnnouncementsPage } from '@backstage-community/plugin-announcements';
import { ProductStructureListExtension } from '@internal/backstage-plugin-product-structure-react';
import { IocodeValidationExtensions } from '@internal/backstage-plugin-iocode-react';
import { appThemes } from './theme/appThemes';
import { UserEmailValidationExtension } from '@internal/backstage-plugin-user-email-validation-react';
import { RenderSupportButtonDrawer } from '@internal/backstage-plugin-support-button-react';
import { ListTopicFieldExtension } from '@internal/backstage-plugin-confluentcloud-list-topics-react';
import { AutoLogout } from '@backstage/core-components';
import { GithubIDFieldValidationExtension } from '@internal/backstage-plugin-github-username-react';
import { ConfluentCloudIdPoolsListFieldExtension } from '@internal/plugin-confluentcloud-idpool-create-react';


const app = createApp({
  apis,
  themes: appThemes,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        title="Select a sign-in method"
        align="center"
        providers={[
          {
            id: 'microsoft-auth-provider',
            title: 'Microsoft',
            message: 'Sign In using Microsoft Azure AD',
            apiRef: microsoftAuthApiRef,
          },
        ]}
      />
    ),
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<Navigate to="home" />} />
    <Route path="/home" element={<HomePage />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      {techDocsPage}
      <TechDocsAddons>
        <ExpandableNavigation />
        <ReportIssue />
        <TextSize />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={
      <ScaffolderPage
        groups={[
          {
            title: '⭐ Favourites',
            filter: entity =>
              entity?.metadata?.tags?.includes('favourite-entity') ?? false,
          },
          {
            title: '🏆 Popular',
            filter: entity =>
              entity?.metadata?.tags?.includes('popular-entity') ?? false,
          },
          {
            title: '🔥 Trending',
            filter: entity =>
              entity?.metadata?.tags?.includes('trending-entity') ?? false,
          },
          {
            title: 'GitHub Tools',
            filter: entity =>
              entity?.metadata?.tags?.includes('github') ?? false,
          },
          {
            title: 'Day 0 - Prep',
            filter: entity =>
              entity?.metadata?.tags?.includes('prep') ?? false,
          },
          {
            title: 'Day 1 - Create',
            filter: entity =>
              entity?.metadata?.tags?.includes('create') ?? false,
          },
          {
            title: 'Day 2 - Manage',
            filter: entity =>
              entity?.metadata?.tags?.includes('manage') ?? false,
          },
          {
            title: 'Day N - Sunset',
            filter: entity =>
              entity?.metadata?.tags?.includes('sunset') ?? false,
          },
          {
            title: 'Community',
            filter: entity =>
              entity?.metadata?.tags?.includes('community') ?? false,
          },
          {
            title: 'Cloud9 Frameworks',
            filter: entity =>
              entity?.metadata?.tags?.includes('cloud9-frameworks') ?? false,
          },
          {
            title: 'Networks',
            filter: entity =>
              entity?.metadata?.tags?.includes('network') ?? false,
          },
          {
            title: 'Confluent Kafka',
            filter: entity =>
              entity?.metadata?.tags?.includes('confluentcloud') ?? false,
          },
          {
            title: 'Integration',
            filter: entity =>
              entity?.metadata?.tags?.includes('integration') ?? false,
          },
          {
            title: 'Community Templates',
            filter: entity =>
              entity?.metadata?.tags?.includes('community-templates') ??
              false,
          },
          {
            title: 'Delete Actions',
            filter: entity =>
              entity?.metadata?.tags?.includes('delete-action') ??
              false,
          },
        ]}
      />
    }>
      <ScaffolderFieldExtensions>
        <TimeStampFieldExtension />
        <SignedInUserExtension />
        <ComponentHopperFieldExtension />
        <ConfluentCloudServiceAccountAccountLimiterFieldExtension />
        <ConfluentCloudServiceAccountsListFieldExtension />
        <AzureResourceGroupFieldValidateExtension />
        <AzureActiveDirectoryFieldExtension />
        <AzureADgroupFieldValidateExtension />
        <UpsertTopicConfigFieldExtension />
        <AzureSubscriptionFieldExtension />
        <AzureNetworkFieldExtension />
        <ValidateAzureAADGrpEmailFieldExtension />
        <BannerForUserRestrictionForCoPilot />
        <CreateEcosystemBuilderCNForm />
        <CreateEcosystemBuilderV2Form />
        <UpdateEcosystemBuilderExtension />
        <UpdateEcosystemBuilderExtensionV2 />
        <GitHubRepositoryExtension />
        <ApplicationFieldCustomExtension />
        <ProductStructureListExtension />
        <IocodeValidationExtensions />
        <UserEmailValidationExtension />
        <ListTopicFieldExtension />
        <GithubIDFieldValidationExtension />
        <ConfluentCloudIdPoolsListFieldExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/notifications" element={<NotificationsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/carbonops" element={<CarbonopsPage />} />
    <Route path="/vault" element={<VaultPage />} />
    <Route path="/worklist" element={<WorklistPage />} />
    <Route path="/worklist/:taskId" element={<WorklistPageDetails />} />
    <Route path="/finops" element={<CostInsightsPage />} />
    <Route path="/service-status" element={<CloudMonitorPage />} />
    <Route path="/timesaver" element={<TimesaverPage />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route path="/explore" element={<ExplorePage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AutoLogout
      idleTimeoutMinutes={40}
    />
    <AlertDisplay />
    <OAuthRequestDialog />
    <SignalsDisplay />
    <AppRouter>
      <RenderSupportButtonDrawer />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
