import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  ListTopic,
  TopicNameValidation,
} from './ListTopics';

export const ListTopicFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'cc-list-topic',
    component: ListTopic,
    validation: TopicNameValidation,
  }),
);
