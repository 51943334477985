import React, { useState } from 'react';
import type { FieldValidation } from '@rjsf/utils';

import { discoveryApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { fetchUser } from './api';
import { z } from 'zod';
import { makeFieldSchemaFromZod } from '@internal/backstage-plugin-scaffolder';
import TextField from '@mui/material/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';


const DOMAIN_MAPPINGS: any = {
  'Service-Account': ["mnscorp.net", "mnscorp.onmicrosoft.com"],
  'User-Account': ["mnscorp.net"],
};

const isValidEmail = (email: string, selector: string) => {
  const validDomains = DOMAIN_MAPPINGS[selector] || DOMAIN_MAPPINGS["User-Account"];
  const regex = new RegExp(`^[a-zA-Z0-9._%+-]+@(${validDomains.join('|')})$`);
  return regex.test(email);
};

let Email: string = '';

export const MandSEmailValidation = ({
  onChange,
  uiSchema,
  schema
}: {
  onChange: (data: any) => void;
  schema: any;
  uiSchema?: any;
}) => {

  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const [userEmail, setUserEmail] = useState<string>('');
  const [emailLoading, setEmailLoading] = useState(false);
  const [error, setError] = useState('');
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const AccountType = String(uiSchema['ui:options']?.selector || 'User-Account');


  const fetchEmailResponse = async (emailAccount: string) => {
    setEmailLoading(true);
    onChange({});
    try {
      const userData = await fetchUser(discoveryApi, fetchApi, emailAccount);
      if (userData && userData?.accountEnabled) {
        if (AccountType === 'Service-Account') {
          onChange({
            userPrincipalName: emailAccount,
            team: userData.displayName,
            role: userData.userType
          });
        } else if (AccountType === 'User-Account') {
          onChange({
            userPrincipalName: emailAccount,
            mail: userData.mail,
            ID: userData.employeeId || '',
            Company: userData.companyName || '',
            Name: userData.displayName || '',
          });
        } else {
          onChange(null);
        }
        setIsValid(true);
        setError('')
        Email = 'exist'
      } else {
        setError('Invalid User Account');
        setIsValid(false);
        Email = 'Not a valid user'
        onChange(null);
      }
    } catch (error: any) {
      console.error("Error fetching user data:", error);
      setError('Invalid User Account');
      onChange(null);
    } finally {
      setEmailLoading(false);
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setUserEmail(email)
    if (isValidEmail(email, AccountType)) {
      fetchEmailResponse(email)
    } else {
      setError('Invalid Email format.Please check the domain');
      setIsValid(false);
      Email = 'Not a valid user'
    }
  };

  return (
    <>
      <TextField
        label={schema.title}
        variant="outlined"
        required
        fullWidth
        value={userEmail}
        style={{ paddingBottom: '20px' }}
        onChange={handleEmailChange}
        color={isValid === true ? 'success' : isValid === false ? 'error' : 'primary'}
        focused={isValid !== null}
        helperText={schema.description}
        slotProps={{
          input: {
            endAdornment: emailLoading ? (
              <InputAdornment position="end">
                <CircularProgress size={24} />
              </InputAdornment>
            ) : null,
          },
        }}
      />
      {error && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
      {isValid && <FormHelperText style={{ color: 'green' }}>Valid Email</FormHelperText>}
    </>
  );
};

export const EmailValidation = (_value: any, validation: FieldValidation) => {
  if (Email === 'Not a valid user') {
    validation.addError('Invalid user email. Please check the details.');
  };
}

const UserEmailExtensionWithOptionsFieldSchema = makeFieldSchemaFromZod(
  z.object({
    UserEmailValidation: z
      .string()
      .email()
      .regex(/@mnscorp\.net$/, 'Email must be from mnscorp.net domain'),
  }),
);

export const UserEmailExtensionWithOptionsSchema = UserEmailExtensionWithOptionsFieldSchema.schema;
