import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import {
  IdPoolsList,
  IdPoolValidation,
} from './IdPoolsListExtension';

export const ConfluentCloudIdPoolsListFieldExtension =
  scaffolderPlugin.provide(
    createScaffolderFieldExtension({
      name: 'cc-list-idpools',
      component: IdPoolsList,
      validation: IdPoolValidation,
    }),
  );
