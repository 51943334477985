import { DiscoveryApi, FetchApi } from "@backstage/core-plugin-api";

export async function fetchUser(discoveryApi: DiscoveryApi, fetchApi: FetchApi, emailAccount: string) {
    try {
        const baseUrl = await discoveryApi.getBaseUrl('msgraph');
        const query = `$select=accountEnabled,companyName,displayName,mail,employeeId,id,userPrincipalName,userType`
        const response = await fetchApi.fetch(`${baseUrl}/v1/users/${emailAccount}?${query}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                }
            });
        if (!response.ok) {
            throw new Error(`Failed to fetch contents, ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        return data.user;
    }
    catch (error: any) {
        console.error(`Error: ${error.message}`);
    }
}