import React from 'react';
import { RepositoryNamePicker } from '@internal/backstage-plugin-catalog-import-alpha';

export const GitHubRepositoryField = ({ onChange}: any) => {
  const handleGithubRepositoryChange = (newValue: any) => {
    onChange(newValue.github_repository_url)
  }

  return (
    <>
      <RepositoryNamePicker handleGithubRepositoryChange={handleGithubRepositoryChange} setBorderColor='rgba(0, 0, 0, 0.87)' setOnHoverColor='rgba(0, 0, 0, 0.87)' variant="standard" />
    </>
  )
};
