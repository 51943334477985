import { scaffolderPlugin } from '@internal/backstage-plugin-scaffolder';
import { createScaffolderFieldExtension } from '@internal/backstage-plugin-scaffolder-react';
import {
  GithubIDValidationExtension,
  GithubIDValidation,
} from './GithubIDValidation';

export const GithubIDFieldValidationExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    name: 'github-id',
    component: GithubIDValidation,
    validation: GithubIDValidationExtension,
  }),
);
