/* eslint-disable no-console */ /* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { FormControl, CircularProgress, TextField, LinearProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useApi, discoveryApiRef, fetchApiRef } from "@backstage/core-plugin-api";
import debounce from "lodash.debounce";
import type { FieldValidation } from '@rjsf/utils';

type TopicConfigType = {
  environment: string;
  topicname: any;
  application_id: string;
  owner: string;
};

type ListTopicProps = {
  onChange: (data: TopicConfigType) => void;
  uiSchema?: any;
  formContext?: any;
};

let TopicName: string = '';

export const ListTopic = ({ onChange, uiSchema, formContext }: ListTopicProps) => {
  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const mandate: boolean = uiSchema['ui:options']?.mandate
    ? uiSchema['ui:options']?.mandate
    : '';

  const dependent = uiSchema['ui:options']?.dependentFields
    ? uiSchema['ui:options']?.dependentFields
    : [];

  const showApprovers = uiSchema['ui:options']?.showApprovers
  ? uiSchema['ui:options']?.showApprovers
  : '';
  const [environment, setEnvironment] = useState<string | null>(null);
  const [topics, setTopics] = useState<{ topicname: string; application_id: string }[]>([]);
  const [filteredTopics, setFilteredTopics] = useState<{ topicname: string; application_id: string }[]>([]);
  const [loadingTopics, setLoadingTopics] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<{ topicname: string; application_id: string } | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [ownerInfo, setOwnerInfo] = useState<any>(null);
  const [loadingOwner, setLoadingOwner] = useState(false);

  const environments = ["dev", "prod"];
  const environmentLabels = {
    dev: "Development",
    prod: "Production",
  };

  const getNestedValue = (obj: any, path: string): any => {
    if (!obj || !path) return undefined;

    return path.split('.').reduce((acc, key) => {
      if (acc && typeof acc === 'object' && key in acc) {
        return acc[key];
      }
      return undefined;
    }, obj);
  };

  useEffect(() => {
    if (dependent.length > 0) {

      // Extract environment value from dependent field
      const dependentField = dependent[0];

      const fieldValue = getNestedValue(
        JSON.parse(JSON.stringify(formContext.formData)),
        dependentField
      );

      if (fieldValue) {
        setEnvironment(fieldValue);
      }
    }
  }, [formContext.formData, dependent]);

  // Fetch topics from backend
  const fetchTopics = async (env: string, searchText: string) => {
    setLoadingTopics(true);
    try {
      const baseUrl = await discoveryApi.getBaseUrl("confluentcloud");
      const response = await fetchApi.fetch(
        `${baseUrl}/list/topics?environment=${env}&searchText=${searchText}&limit=100&page=1`
      );
      const data = await response.json();
      setTopics(data);
      setFilteredTopics(data.slice(0, 100));
    } catch (error) {
      throw new Error(`Error fetching topics:${error}`);
    } finally {
      setLoadingTopics(false);
    }
  };


  useEffect(() => {
    if (!environment) {
      setSelectedTopic(null);
      setEnvironment(null);
      setOwnerInfo(null);
      setInputValue("");
      if (mandate === true) {
        TopicName = 'Topicname is empty';
      }
      onChange({ environment: '', topicname: null, owner: '', application_id: '' });
      return;
    };
    fetchTopics(environment, "");
  }, [environment]);

  const debouncedSearch = useCallback(
    debounce((query: string) => {
      if (environment) fetchTopics(environment, query);
    }, 300),
    [environment]
  );

  // Handle search input changes
  const handleTopicSearch = (_event: React.ChangeEvent<{}>, value: string) => {
    setInputValue(value);
    if (value.length === 0) {
      setSelectedTopic(null);
      setOwnerInfo(null);
      setFilteredTopics([...topics.slice(0, 100)]);
    } else {
      debouncedSearch(value);
    }
  };

  // Fetch owner info
  const fetchOwnerInfo = async (applicationId: string) => {
    setLoadingOwner(true);  // Start loading
    try {
      const baseUrl = await discoveryApi.getBaseUrl("msgraph");
      const query = `filter=fields%2FTitle+eq+%27${applicationId}%27`;
      const response = await fetchApi.fetch(`${baseUrl}/v2/sharepoint/MAL/filteredMal?${query}`);
      const data = await response.json();
      return data.items[0]?.owner || null;
    } catch (error) {
      console.error("Error fetching owner info:", error);
      return null;
    } finally {
      setLoadingOwner(false);
    }
  };

  // Handle topic selection
  const handleTopicSelect = async (_event: React.ChangeEvent<{}>, value: { topicname: string; application_id: string } | null) => {
    setSelectedTopic(value);
    if (value !== null) {
      TopicName = 'Topicname is selected';
    }
    if (value === null) {
      setOwnerInfo(null);
      TopicName = 'Topicname is empty';
    }
    setInputValue(value ? value.topicname : "");
    if (value?.application_id) {
      const owner = await fetchOwnerInfo(value.application_id);
      setOwnerInfo(owner);
      onChange({ environment: environment!, topicname: value.topicname, application_id: value.application_id, owner: owner });
    }
    else {
      setOwnerInfo(showApprovers);
      onChange({ environment: environment!, topicname: value?.topicname, application_id: '', owner: showApprovers })
    }
  };

  return (
    <div>
      {/* Environment Selector */}
      {/* Show environment dropdown only if there's no dependent field */}
      {dependent.length === 0 && (
        <FormControl fullWidth margin="normal">
          <Autocomplete
            options={environments}
            getOptionLabel={(option) => environmentLabels[option as keyof typeof environmentLabels] || option}
            value={environment}
            onChange={(_event, value) => {
              setEnvironment(value);
              setSelectedTopic(null);
              setInputValue("");
              setFilteredTopics([]);
              setOwnerInfo(null);
              onChange({ environment: value || "", topicname: null, application_id: "", owner: "" });
            }}
            renderInput={(params) => <TextField {...params} label="Select Environment" variant="outlined" />}
          />
        </FormControl>
      )}


      {/* Topic Autocomplete */}
      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={filteredTopics}
          getOptionLabel={(option) => option.topicname || ""}
          inputValue={inputValue}
          value={selectedTopic}
          onInputChange={(_event, value) => {
            setOwnerInfo(null);
            handleTopicSearch(_event, value)
          }}
          onChange={(_event, value, reason) => {

            if (reason === "clear") {
              setSelectedTopic(null);
              setOwnerInfo(null);
              onChange({ environment: environment!, topicname: null, application_id: '', owner: '' });
              fetchTopics(environment!, "");
              return;
            }
            handleTopicSelect(_event, value)
          }}
          loading={loadingTopics}
          clearOnBlur={false}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Topics"
              variant="outlined"
              placeholder="Start Typing...."
              required
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loadingTopics ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </FormControl>

      {/* Owner Information Display */}
      {loadingOwner ? (
        <div>
          <p>Fetching Owner Details...</p>
          <LinearProgress />
        </div>
      ) : ownerInfo && (
        <div>
          <h3>Owner Details: {(JSON.stringify(ownerInfo, null, 2) === '"TBC"') ? showApprovers : JSON.stringify(ownerInfo, null, 2)}</h3>
          <pre>Approval Request will be send to the Owner mentioned here.</pre>
        </div>
      )}
    </div>
  );
};


export const TopicNameValidation = (_value: any, validation: FieldValidation) => {
  if (TopicName === 'Topicname is empty') {
    validation.addError('Topicname Selection is empty. Select a valid Topicname');
  }
};
