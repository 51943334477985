import React, { useState } from 'react';
import {
  useApi,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { fetchGithubId } from './api';
import type { FieldValidation } from '@rjsf/utils';
import {
  CircularProgress,
  FormHelperText,
  InputAdornment,
  TextField,
} from '@material-ui/core';

let GitID: string = '';

export const GithubIDValidation = ({ 
  onChange, 
  schema 
}: any) => {


  const discoveryApi = useApi(discoveryApiRef);
  const fetchApi = useApi(fetchApiRef);

  const [githubId, setGithubId] = useState('');
  const [githubIDValidationMessage, setGithubIDValidationMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleGithubIDChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const idValue = event.target.value;
    setGithubId(idValue);
    setLoading(true);
    setGithubIDValidationMessage('');

    if (!idValue) {
      setLoading(false);
      setGithubIDValidationMessage('');
      setError(false)
      return;
    }
    if (idValue) {
      try {
        const response = await fetchGithubId(idValue, discoveryApi, fetchApi);

        if (response?.company && response?.name) {
          setGithubIDValidationMessage('Valid GitHub ID');
          setError(false);
          onChange({
            GithubId: idValue,
            CompanyName: response.company || '',
            GithubName: response.name || '',
          });
          GitID = 'exists';
        }else if (response === 'Error checking user ID') {
          setGithubIDValidationMessage('GitHub User not found');
          setError(true);
          GitID = 'doNotExists';
        }
        else {
          setGithubIDValidationMessage('Company name or GitHub username is missing in the user profile');
          setError(true);
          GitID = 'doNotExists';
        }
      } catch (error: any) {
        console.error('Error fetching user data:', error);
        setGithubIDValidationMessage('Error fetching user data');
        setError(true);
        GitID = 'doNotExists';
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <TextField
        label={schema.title}
        helperText={schema.description}
        value={githubId}
        onChange={handleGithubIDChange}
        fullWidth
        required
        variant="outlined"
        error={error}
        InputProps={{
          endAdornment: loading ? (
            <InputAdornment position="end">
              <CircularProgress size={24} />
            </InputAdornment>
          ) : null,
        }}
      />
      {githubIDValidationMessage && (
        <FormHelperText>
          <div style={{ color: error ? 'red' : 'green' }}>
            {githubIDValidationMessage}
          </div>
        </FormHelperText>
      )}
    </>
  );
};

export const GithubIDValidationExtension = (
  _value: any,
  validation: FieldValidation,
) => {
  if (GitID === 'doNotExists') {
    validation.addError('Github Id does not exist');
  }
};
