import { DiscoveryApi, FetchApi } from "@backstage/core-plugin-api";

export async function fetchGithubId(githubId: string, discoveryApi: DiscoveryApi, fetchApi: FetchApi) {
    const baseUrl = await discoveryApi.getBaseUrl('github');
    const apiUrl =`${baseUrl}/users/${githubId}`

    try {
        const response = await fetchApi.fetch(apiUrl)
        const gitResponse = await response.json()
        return gitResponse; // Return the entire response
    } catch (error) {
        console.error("Error fetching GitHub user information:", error);
        throw error; // Throw the error for the calling function to handle
    }
}
