import React from 'react';
import { EntityLayout } from '@backstage/plugin-catalog';
import { CICDTabContent } from '../TabContent/CICDTabContent';
import { OverviewTabContent } from '../TabContent/OverViewTabContent';
import { TechDocsTabContent } from '../TabContent/TechDocTabContent';
import { IntegrationTabContent } from '../TabContent/IntegrationTabContent';
import { CodeHealthTabContent } from '../TabContent/CodeHealthTabContent';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';

export const WebsiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {OverviewTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {CICDTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {TechDocsTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/code-health" title="Code Health">
      {CodeHealthTabContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/integrations" title="Integrations">
      {IntegrationTabContent}
    </EntityLayout.Route>

  </EntityLayout>
);
